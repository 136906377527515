import React from "react";
import "./about_us.scss";
import GlitchHeader from "../../components/cool_stuff/glitch_header/GlitchHeader";

export default class AboutUs extends React.Component<any, any> {
  render() {
    const me = this;
    return <div className={"open-source"}>
      <GlitchHeader content={"Мы"} />
      <h3 style={{opacity: 0.9}} className="typed-text">производим демократизацию ИИ:</h3>
      <ul style={{opacity: 0.9}} className="typed-list">
        <li className="typed-item"><b>Освещаем открытые технологии</b></li>
        <li className="typed-item"><b>Развиваем и распространяем открытые данные</b></li>
        <li className="typed-item"><b>Поддерживаем инклюзивное сообщество</b></li>
      </ul>
      <p style={{color: "var(--white)"}} className="typed-text">Кластер проводит мероприятия, развивющие открытые продукты, датасеты и сообщество.</p>
      <br/>
      <button className={"sport"} onClick={() => {me.props.setSide(1);}} style={{textShadow: "0px 0px 5px rgba(0,0,200, 0.2)"}}>Узнать больше</button>
    </div>
  }
}