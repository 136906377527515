import React from "react";
import {ApiEngine} from "api-engine";
import NewsItem from "./NewsItem";
import "./style/news-page-content.scss";
import ModalWindow from "../../modal/ModalWindow";

interface NewsPageContentProps {
    api: ApiEngine;
}

interface NewsPageContentState {
    news: any[];
    shownNewsIds: { [key: string]: boolean };
    activeNewsArticle: any | null;
    filter: string;
}

const news = [
    {
        alt_headline: "Поиск рукописного Грааля запустился!",
        article_abstract: "Офис",
        author_name: "Константин Скобельцын",
        backstory: "Старт первого хакатона",
        body: "Первый хакатон по разметке данных запущен! <br><p>Тема хакатона - <b>Почерк</b>. <p>Нужно собрать и разметить датасет, состоящий из изображений рукописных слов.Результаты хакатона будут оформлены в открытый датасет.</p><p>Участников и призеров ждут ценные призы!</p><br/><p>Регистрация, уже доступна!</p><br><p>Хакатон продлится до 23:59&nbsp;26.11.2024!</p>",
        date_of_publication: "2988-03-14T07:02:01Z",
        id: "5cff28a0-245d-4428-9bcf-4bdb70789555",
        keywords: "Хакатон; Соревнования; ИИ; Датасеты",
        lead: "Хакатон запустился!",
        section_title: "Хакатоны",
        thumbnail_url: "/api/uploads/hackathon2.png",
        title: "Хакатон идет"
    },
    {
        alt_headline: "Поиск рукописного Грааля",
        article_abstract: "Офис",
        author_name: "Константин Скобельцын",
        backstory: "Старт первого хакатона",
        body: "Мы запускаем первый хакатон! Присоединяйся к нам и войди в мир ИИ!<br><p>Тебя ждет хакатон <b>Почерк</b>. Суть задачи проста - собрать и разметить датасет, состоящий из изображений рукописных слов. Датасеты будут открыты в общий доступ для обучения открытых моделей.</p><br/><p>Регистрация, уже доступна!</p><br><p>Хакатон начнется 14.11.2024 в 17:00 и продлится до 27 ноября!</p>",
        date_of_publication: "2988-03-14T07:02:01Z",
        id: "5cff28a0-245d-4428-9bcf-4bdb70789545",
        keywords: "Хакатон; Соревнования; ИИ; Датасеты",
        lead: "Мы запускаем первый хакатон!",
        section_title: "Хакатоны",
        thumbnail_url: "/api/uploads/hackathon1_with_logo.png",
        title: "Первый хакатон"
    },
]

export default class News extends React.Component<NewsPageContentProps, NewsPageContentState> {
    constructor(props: NewsPageContentProps) {
        super(props);
        this.state = {
            news: [],
            shownNewsIds: {},
            activeNewsArticle: null,
            filter: ""
        };
    }

    componentDidMount() {
        const url = "/api/news/index";

        this.setState({news: news});
        // this.props.api.asyncFetch(url, {}).then((response) => {
        //     const res = response.news_articles;
        //     this.setState({ news: res });
        // });
    }

    render() {
        const me = this;

        return <>
            <div className="news-page-content" onClick={(e) => {
                e.stopPropagation();
            }}>
                <div className={"news-page-content-circles"}>
                    {this.state.news.map((news, index) => {
                        return <div className="news-page-content-circle" key={index} onClick={() => {
                            me.setState({activeNewsArticle: news});
                        }}>
                            <img src={`${me.props.api.serverUrl}/${news.thumbnail_url}`} alt={news.title} />
                            <p>{news.title}</p>
                        </div>
                    })}
                </div>
                <div className="news-list">
                    {this.state.news.filter((x) => {
                        if (me.state.filter.startsWith("#")) {
                            let res = x.keywords.toString()
                                .split(";").map((x: string) => {
                                    return x.toLowerCase().trim();
                                });
                            console.log(res);
                            let filter = me.state.filter.toLowerCase().trim();
                            console.log(filter)
                            console.log(res.indexOf(filter))
                            return res.indexOf(filter.slice(1,1000000)) > -1;
                        }
                        return JSON.stringify(x).toLowerCase().indexOf(me.state.filter.toLowerCase()) !== -1;
                    }).map((news, index) => {
                        return <NewsItem api={this.props.api}
                                         news={news}
                                         key={index}
                                         filterCallback={(filter) => {
                                             me.setState({filter: `#${filter.trim()}`});
                                         }}
                        />

                    })}

                </div>

            </div>

            {me.state.activeNewsArticle && <ModalWindow onClick={() => {
                me.setState({activeNewsArticle: null});
            }}>
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    style={{width: "90%", height: "80%", zIndex: 1000, paddingTop: "20px", paddingBottom: "20px", backgroundColor: "var(--white)", overflowY: "auto", overflowX: "hidden"}}>
                    <NewsItem api={this.props.api} news={me.state.activeNewsArticle} filterCallback={(filter) => {
                        me.setState({filter: `#${filter.trim()}`});
                    }} />
                </div>
            </ModalWindow>}
        </>;
    }
}