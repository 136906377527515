import React from "react";
import "./community.scss";
import GlitchHeader from "../../components/cool_stuff/glitch_header/GlitchHeader";

export default class Community extends React.Component<any, any> {
  render() {
    const me = this;
    return <div className={"community"}>
      <GlitchHeader content={"Сообщество"} />
      <p>Чтобы участвовать в мероприятиях кластера<br/>нужно</p>
      <br/>
      <div className={"flex flex-row justify-between bevel"}>
        {Array(3).fill(null).map((_, i) => (
          <i key={i} className={"fas fa-3x fa-arrow-down"}/>
        ))}
      </div>
      <button style={{background: "var(--sport-lime)", color: "var(--dart-black)"}} onClick={me.props.startRegistration} >зарегистрироваться</button>
    </div>
  }
}